import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Us'
            subheader='Everything is Possible, Latest Tech Reviews & Find DIY Creative Ideas To Improve Lifestyle!
.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} to='/contact' sx={styles.button}>
              Contact Me
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='World is Changing...'>
                <Text variant='p'>
                  Technology has made it possible to travel across different planets, World is changing with New Technologies. 
                </Text>
                 <Text variant='p'>
                  We offer Tech News, Tech Updates, Latest Tech Reviews on Latest Gadgets, Latest Mobiles, Latest Computers & Artificial Intelligence.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Tech Reviews'>
                <Card variant='paper'>
                  Find Tech Reviews on Different Top Notch Tech Products, 
                </Card>
              </Section>
              <Divider />
             
              <Divider />
              <Section title='Creative DIY Projects'>
                <Card variant='paper'>
                 Change your lifestyle with Our DIY Creatvie Projects to Improve Lifestyle.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
